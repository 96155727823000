import { createReducer } from "@reduxjs/toolkit";
import {
  getCategoryDropdownSuccess,
  getTrainingDropdownSuccess,
  getTrainingEmployeesSuccess,
  getTrainingHistoryListSuccess,
  getTrainingListSuccess,
  getTrainingPackListSuccess,
  loadingList,
  setTrainingGenralInfo,
  setDocApprovalNeeded,
  updateIsSignOrImageRequired,
  updateTrainingEmployeeStateSuccess,
  updateTrainingName,
  getTrainingList,
  setSelectedTrainingEmployees,
  updateStatus,
  updateTabNameState,
  setTriggerSignClearCount,
  //trainingActionType,
} from "./trainingActions";

export interface ITraingCourses {
  triggerSignClearCount: any;
  trainigCourseDropdown?: any[];
  trainigs: Array<any>;
  trainingPackList: any[];
  isLoading?: boolean;
  dataCount?: number;
  traininingCategories?: any[];
  trainingEmployees?: any[];
  trainigHistoryList?: any[];
  historydataCount?: number;
  trainingName: any;
  docApproveNeeded?: boolean;
  isSignOrImageRequired: boolean;
  trainingGeneral?: any;
  selectedEmployees: any;
  statusUpdated: boolean;
  currentTabName: any;
}

const initialState: ITraingCourses = {
  trainigs: [],
  trainingPackList: [],
  trainingName: "",
  isSignOrImageRequired: false,
  docApproveNeeded: false,
  trainingEmployees: [],
  selectedEmployees: "",
  statusUpdated: false,
  currentTabName: "",
  triggerSignClearCount: 0,
};
export const trainingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTrainingDropdownSuccess, (state, action) => {
      state = {
        ...state,
        trainigCourseDropdown: action.payload?.data,
      };
      return state;
    })
    .addCase(setTriggerSignClearCount, (state, action) => {
      state = {
        ...state,
        triggerSignClearCount: action.payload,
      };
      return state;
    })
    .addCase(getTrainingListSuccess, (state, action) => {
      state = {
        ...state,
        trainigs: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getTrainingList.pending, (state, action) => {
      state = {
        ...state,
        isLoading: true,
      };
      return state;
    })
    .addCase(getTrainingList.fulfilled, (state, action) => {
      state = {
        ...state,
        isLoading: false,
        trainigs: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getTrainingHistoryListSuccess, (state, action) => {
      state = {
        ...state,
        trainigHistoryList: action.payload?.data,
        historydataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(updateTrainingName, (state, action) => {
      state = {
        ...state,
        trainingName: action.payload,
      };
      return state;
    })
    .addCase(getCategoryDropdownSuccess, (state, action) => {
      state = {
        ...state,
        traininingCategories: action.payload,
      };
      return state;
    })
    .addCase(getTrainingPackListSuccess, (state, action) => {
      state = {
        ...state,
        trainingPackList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getTrainingEmployeesSuccess, (state, action) => {
      state = {
        ...state,
        trainingEmployees: action?.payload,
      };
      return state;
    })
    .addCase(updateTrainingEmployeeStateSuccess, (state, action) => {
      state = {
        ...state,
        trainingEmployees: action.payload,
      };
      return state;
    })
    .addCase(updateIsSignOrImageRequired, (state, action) => {
      state = {
        ...state,
        isSignOrImageRequired: action.payload,
      };
      return state;
    })
    .addCase(setDocApprovalNeeded, (state, action) => {
      state = {
        ...state,
        docApproveNeeded: action.payload,
      };
      return state;
    })
    .addCase(setTrainingGenralInfo, (state, action) => {
      state = {
        ...state,
        trainingGeneral: action?.payload,
      };
      return state;
    })
    .addCase(setSelectedTrainingEmployees, (state, action) => {
      state = {
        ...state,
        selectedEmployees: action?.payload,
      };
      return state;
    })
    .addCase(updateStatus, (state, action) => {
      state = {
        ...state,
        statusUpdated: action?.payload,
      };
      return state;
    })
    .addCase(updateTabNameState, (state, action) => {
      state = {
        ...state,
        currentTabName: action?.payload,
      };
      return state;
    });
});
