import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import trainingDataAccess from "src/dataAccess/trainings/trainingDataAccess";
import trainingPackDataAccess from "src/dataAccess/trainings/trainingPackDataAccess";
import { IListType } from "../store";

export const getTrainingDropdownSuccess = createAction<any>(
  "[Training] Get training dropdown List Success"
);
export const loadingList = createAction<boolean>("[Training] Loading data");
export const updateTrainingName = createAction<string>(
  "[Training] Update Name"
);

export const updateTabNameState = createAction<string>(
  "[Training] Update Tab Name"
);

export const getTrainingDropdown = createAsyncThunk<any>(
  "[Training] Get training dropdown List Success",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await trainingDataAccess
      .getTrainingDropdown()
      .then((res) => {
        thunkAPI.dispatch(getTrainingDropdownSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
export const getTrainingListSuccess = createAction<any>(
  "[Training] Get training List Success"
);
export const getTrainingList = createAsyncThunk<any, IListType>(
  "[Training] Get training List Success",
  async (parameter: IListType, thunkAPI) => {
    const result = await trainingDataAccess
      .getAll(parameter)
      .then((res) => res);
    return result;
  }
);

export const getCategoryDropdownSuccess = createAction<any>(
  "[Training] Get category dropdown success"
);

export const getCategoryDropdown = createAsyncThunk<any>(
  "[Training] Get category dropdown",
  async (parameter, thunkAPI) => {
    const result = await trainingDataAccess
      .getTrainingCategoryDropdownList()
      .then((res) => {
        thunkAPI.dispatch(getCategoryDropdownSuccess(res.data));
        return res;
      });
    return result;
  }
);

export const getTrainingPackListSuccess = createAction<any>(
  "[Training Pack] Get training pack list success"
);
export const getTrainingPacksAsync = createAsyncThunk<any, IListType>(
  "[Training Pack] Get training pack list",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    const result = await trainingPackDataAccess
      .getTrainingPackList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getTrainingPackListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
export const getTrainingEmployeesSuccess = createAction<any[]>(
  "[Training] Get Training Employees Success"
);
export const getTrainingEmployees = createAsyncThunk<any, number>(
  "[Training] Get Training Employees",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    console.log("Api call start: ");
    console.log(new Date());
    const result = await trainingDataAccess
      .getTrainingEmployees(parameter)
      .then((res) => {
        console.log("api call end: ");
        console.log(new Date());
        thunkAPI.dispatch(getTrainingEmployeesSuccess(res?.data));
        console.log("redux store: ");
        console.log(new Date());
        thunkAPI.dispatch(loadingList(false));
        console.log("rendering end: ");
        console.log(new Date());
        return res;
      });
    return result;
  }
);
export const updateIsSignOrImageRequired = createAction<any>(
  "[Training] Update SignOrImageRequired"
);
export const updateTrainingEmployeeStateSuccess = createAction<any>(
  "[Training] Update raining Employees Success"
);
export const updateTrainingEmployeeState = createAsyncThunk<any, any[]>(
  "Update raining Employees ",
  async (parameter: any[], thunkAPI) => {
    thunkAPI.dispatch(updateTrainingEmployeeStateSuccess(parameter));
    //return result;
  }
);
interface trainingHistoryListType extends IListType {
  trainingId: number;
  userId: number;
}
export const getTrainingHistoryListSuccess = createAction<any>(
  "[Training] Get training History List Success"
);
export const setTriggerSignClearCount = createAction<any>(
  "[Training] Set Trigger Sign Clear Count"
);

export const getTrainingHistoryList = createAsyncThunk<
  any,
  trainingHistoryListType
>(
  "[Training] Get training History List",
  async (parameter: trainingHistoryListType, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await trainingDataAccess
      .getUsertrainingHistory(parameter)
      .then((res) => {
        thunkAPI.dispatch(getTrainingHistoryListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);

export const setDocApprovalNeeded = createAction<boolean>(
  "[Training] doc approval needed"
);

export const setTrainingGenralInfo = createAction<any>(
  "[Training] Set training general info success"
);
export const setSelectedTrainingEmployees = createAction<any>(
  "[Training] Set Selected Training Employees"
);
export const updateStatus = createAction<any>("[Training] Update Status");
