import { IFilter } from "src/@redux/listRedux";
import { DeparmentDeleteParameter } from "src/models/common.models";
import commonDataAccess from "../commonDataAccess";

class trainingDataAccess {
  getTrainingDropdown = () => {
    return commonDataAccess.getData("training/get/dropdownlist");
  };
  getAll = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("training/getall", parameters);
  };
  getTrainingCategoryDropdownList = () => {
    return commonDataAccess.getData("training/get/categoryDropdownList");
  };
  getTrainingEditGeneral = (trainingId: number) => {
    return commonDataAccess.getData("training/get/trainingGeneral", {
      id: trainingId,
    });
  };
  saveTrainingGeneralInfo = (payload: any) => {
    return commonDataAccess.postFormData(
      "training/save/trainingGeneral",
      payload
    );
  };
  saveEmployees = (
    trainingId: number,
    selectedUsers: string,
    selectedDepts: string
  ) => {
    return commonDataAccess.postData("training/save/employees", null, {
      trainingId: trainingId,
      selectedDepartmentIds: selectedDepts,
      selectedUserIds: selectedUsers,
    });
  };
  getTrainingEmployees = (trainingId: number) => {
    return commonDataAccess.getData("training/get/employees", {
      trainingId: trainingId,
    });
  };
  saveEmployeeDetails = (payload: any, trainingId: number) => {
    return commonDataAccess.postData("training/save/employeedetails", payload, {
      trainingId: trainingId,
    });
  };
  saveTrainerSignature = (payload: any) => {
    return commonDataAccess.postData("training/save/trainersignature", payload);
  };
  saveTraineeSignature = (payload: any) => {
    return commonDataAccess.postData("training/save/traineesignature", payload);
  };
  getTrainingEmployeeDepartments = (trainingId: number, isUser: boolean) => {
    return commonDataAccess.getData("training/get/employeeUserorDepartments", {
      id: trainingId,
      isUser: isUser,
    });
  };
  getallminifiedData = (sortPreference: any) => {
    return commonDataAccess.getData("training/getall/minified", {
      sortPreference: sortPreference,
    });
  };
  saveUserTraining = (userId: number, selectedTrainingIds: string) => {
    return commonDataAccess.postData("training/save/usertraining", null, {
      userId: userId,
      selectedTrainingIds: selectedTrainingIds,
    });
  };
  delete = (ids) => {
    return commonDataAccess.deleteData("training/delete", { ids: ids });
  };
  saveTrainingCategory = (payload: any) => {
    return commonDataAccess.postData("training/save/trainingCategory", payload);
  };
  deleteTrainingCategory = (trainingId: number, categoryId: number) => {
    return commonDataAccess.deleteData("training/delete/trainingCategory", {
      trainingId: trainingId,
      categoryId: categoryId,
    });
  };
  getAssociatedTrainingbycategory = (
    trainingid: number,
    categoryid: number
  ) => {
    return commonDataAccess.getData(
      "training/get/associatedtrainingbycategory",
      {
        trainingId: trainingid,
        categoryId: categoryid,
      }
    );
  };
  getTrainingDetail = (id: number) => {
    return commonDataAccess.getData("training/get/trainingemployeedetail", {
      id: id,
    });
  };
  saveTrainingDetail = (payload: any) => {
    return commonDataAccess.postFormData(
      "training/save/trainingemployeedetail",
      payload
    );
  };

  deleteTrainingDetail = (id) => {
    return commonDataAccess.deleteData("training/delete/deleteTrainingDetail", {
      id: id,
    });
  };
  getUsertrainingHistory = (params: {
    trainingId;
    userId;
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      trainingId: params.trainingId,
      userId: params.userId,
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData(
      "training/get/usertrainingHistory",
      parameters
    );
  };
  updateTrainingDetailStatus = (status: any, trainingDtlIds: any) => {
    return commonDataAccess.postData(
      "training/update/trainingStatusDetail",
      null,
      { status: status, trainingDtlIds: trainingDtlIds }
    );
  };
  saveTrainingDoc = (payload) => {
    return commonDataAccess.postFormData("training/save/trainingDoc", payload);
  };

  getTrainingDocApprovalList = () => {
    return commonDataAccess.getData("training/get/documenttoapprove");
  };

  getTrainingMatrix = (
    pageNo: number = 1,
    pageSize: number = 25,
    departmentId: any = "",
    trainingStatus: any = ""
  ) => {
    return commonDataAccess.getData("training/get/training-matrix", {
      pageNo: pageNo,
      pageSize: pageSize,
      departmentIds: departmentId,
      trainingStatus: trainingStatus,
    });
  };

  sendTrainingRecordMail = (trainingId: any, userIds: any) => {
    return commonDataAccess.getData(
      `training/emailtrainingrecord/${trainingId}/${userIds}`
    );
  };
  exportTrainingMatrixToExcel = (departmentId: any, trainingStatus: any) => {
    return commonDataAccess.downloadFile(
      "training/export/trainingMatrixExcel",
      {
        departmentIds: departmentId,
        trainingStatus: trainingStatus,
      }
    );
  };
  exportTrainingRecordsToExcel = (type = "excel") => {
    return commonDataAccess.downloadFile("training/export/trainingrecords", {
      type: type,
    });
  };
  getVideoLocation = (trainingId: any) => {
    return commonDataAccess.getData("training/get/videolocation", {
      trainingId: trainingId,
    });
  };
  getRecordByDepartment = (departmentId: any) => {
    return commonDataAccess.getData(
      `training/get/byDepartment/${departmentId}`
    );
  };
  getRecordDetailForDepartment = (departmentId: any) => {
    return commonDataAccess.getData(
      `training/get/departmentTrainingRecord/${departmentId}`
    );
  };
  saveTrainingRecordForDepartment = (payload: any) => {
    return commonDataAccess.putData(
      "training/save/trainingRecordDepartment",
      payload
    );
  };
  addTrainingToDepartment = (departmentId: any, trainingIds: string) => {
    return commonDataAccess.postData(
      "training/add/trainingToDepartment",
      null,
      {
        departmentId: departmentId,
        trainingIds: trainingIds,
      }
    );
  };
  deleteTrainingFromDepartment = (parameters: DeparmentDeleteParameter) => {
    return commonDataAccess.deleteData(
      "training/delete/trainingFromDepartment",
      {
        departmentId: parameters.departmentId,
        trainingIds: parameters.trainingIds,
      }
    );
  };
  importFileData = (payload: any) => {
    return commonDataAccess.postFormData("training/importData", payload);
  };
  importEmployeeFileData = (payload: any) => {
    return commonDataAccess.postFormData("training/importEmployees", payload);
  };
}
export default new trainingDataAccess();
