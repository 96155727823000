export const keys = {
  authorization: "AUTHORIZATION",
};

export const defaultPageSize = 25;

export const filterOptions = {
  startsWith: { label: "Starts with", value: "StartsWith" },
  contains: { label: "Contains", value: "Contains" },
  isNotNull: { label: "Is not null", value: "IsNotNull" },
  equals: { label: "Equals", value: "Equals" },
  notEquals: { label: "Not Equals", value: "NotEquals" },
  lessThanEqual: { label: "Less then equal", value: "LessThanEqual" },
  greaterThanEqual: { label: "Greater than equal", value: "GreaterThanEqual" },
  greaterThan: { label: "Greater than", value: "GreaterThan" },
};

export const stateList = [
  {
    label: "ACT",
    value: "ACT",
  },
  {
    label: "NSW",
    value: "NSW",
  },
  {
    label: "NT",
    value: "NT",
  },
  {
    label: "QLD",
    value: "QLD",
  },
  {
    label: "SA",
    value: "SA",
  },
  {
    label: "TAS",
    value: "TAS",
  },
  {
    label: "VIC",
    value: "VIC",
  },
  {
    label: "WA",
    value: "WA",
  },
  {
    label: "OTH",
    value: "OTh",
  },
];

export const salaryModes = [
  {
    label: "Annually",
    value: "1",
  },
  {
    label: "Hourly",
    value: "2",
  },
];

export const localStorageKeys = {
  user: "a3j3d$s",
  authorization: "s7asl3de2",
  reportApiAuthorization: "",
};

export const CRMEntityTypes = {
  Company: 1,
  Individual: 2,
  Employee: 3,
  Contact: 4,
};

export const userTypes = {
  user: "USER",
  admin: "ADMIN",
  superAdmin: "SUPER_ADMIN",
};

export const DocStatusMap = {
  Approved: "Approved",
  AwaitingApproval: "Awaiting Approval",
  Rejected: "Rejected",
};

export const ProcedureStatusMap = {
  verifed: "Approved",
  verifiyNeeded: "To Be Approved",
};

export const FrequencyMap = {
  WhenVerChange: "When Version Changes",
  WeeklyWhenVerChange: "Weekly or When Version Changes",
  MonthlyWhenVerChange: "Monthly or When Version Changes",
  AnnuallyWhenVerChange: "Annually or When Version Changes",
  Every2YearsWhenVerChange: "Every 2 Years or When Version Changes",
  Every3YearsWhenVerChange: "Every 3 Years or When Version Changes",
};
export const ModuleNameMap = {
  ProceduresCALists: "Lists",
  ProceduresProcedures: "Checks",
  ProceduresViewCompletedProcedures: "View Completed Checks",
  ProceduresDocuments: "Documents",
  ProceduresInProgressChecks: "In Progress Checks",
  ProceduresProceduresReport: "Checks Report",
  ProceduresSheets: "Tabs",
  CorrectiveActionsCorrectiveActionsList: "NC List",
  CorrectiveActionsChecklists: "Checklists",
  CorrectiveActionsExceptions: "NC",
  CorrectiveActionsCorrectiveActionReports: "Non Conformance Reports",
  CorrectiveActionsOverdueCAsReports: "Overdue NC Reports",
  CorrectiveActionsActionTakenList: "Action Taken List",
  CorrectiveActionsComplaints: "Complaints",
  CorrectiveActionsIncident: "Incident",
  CorrectiveActionsAccident: "Accident",
  LocationsLocationsOrCCPs: "Locations / CCPs",
  LocationsRackLocations: "Rack Locations",
  CalendarEvents: "Events",
  CalendarCalendarReports: "Calendar Reports",
  CalendarToDoList: "To Do List",
  DocumentationDocumentChangeManagement: "Document Change Management",
  DocumentationTastePanel: "Taste Panel",
  DocumentationDocuments: "Documents",
  DocumentationCompliants: "Complaints",
  DocumentationReturns: "Returns",
  DocumentationEmailHistory: "Email History",
  DocumentationMessageTool: "Message Tool",
  DocumentationTrainingRecords: "Training Records",
  DocumentationTrainingPacks: "Training Packs",
  TrainingTrainingCourses: "Training Courses",
  TrainingCompletedTrainingCourses: "Completed Courses",
  TrainingApprovalList: "Approval List",
  MaintenancePeople: "People",
  MaintenanceSettings: "Settings",
  MaintenanceOrganizations: "Organizations",
  MaintenanceDepartments: "Departments",
  MaintenanceDeviceManagement: "Device Management",
  MaintenanceVisitors: "Visitors",
  CautionsCautions: "Cautions",
  ProductsProducts: "Products",
  ProductsCategories: "Categories",
  ProductsFinishedProducts: "Finished Products",
  ProductsPricing: "Pricing",
  ProductsPicking: "Picking",
  HandsetGoodsInReceivedByDate: "Handset Goods In Received By Date",
  SuppliersSuppliers: "Suppliers",
  StockControlPurchaseOrder: "Purchase Order",
  SuppliersServiceCompanies: "Service Companies",
  SuppliersDeliveryBy: "Delivery By",
  CustomersCustomers: "Customers",
  StockControlSalesOrder: "Sales Order",
  ProceduresReportsTimeTaken: "Report Time Taken",
  ProceduresReportsStepValues: "Report Step Values",
  ProceduresReportsStepScore: "Report Step Score",
  ProceduresReportsProcedureScore: "Report Procedure Score",
  CorrectiveActionsReportsNumberOfCorrectiveActions:
    "Report Number Of Corrective Actions",
  LocationsReportsItem: "Report Items",
  ProductsReportsProducts: "Report Products",
  ProceduresReportsTally: "Report Tally",
  CorrectiveActionsReportsMostCommonCAsByStep: "Report Most Common CAs By Step",
  DocumentationReportsTrainingRecord: "Report Training Record",
  DocumentationReportsTastePanel: "Report Taste Panel",
  DocumentationReportsCompliant: "Complaints Report",
  ProductionReport: "Report Production",
  ProductionRecipes: "Recipes",
  ProductionViewCompletedRecipes: "Completed Recipes",
  ProductionInProgressRecipes: "In Progress Recipes",
  ProductionDowntimeReason: "Downtime Reason",
  GoodsInChecks: "Goods In",
  GoodsInCompletedChecks: "Completed Goods In",
  GoodsInProcessNewOrder: "Process New Order",
  GoodsInArchieveCompletedChecks: "Completed Archived Goods In",
  GoodsInDiscripencies: "View Discrepancies",
  GoodsInQuarantine: "Quarantine",
  GoodsInInProgress: "In Progress Goods In",
  AuditsChecks: "Audits",
  AuditsCompletedChecks: "Completed Audits",
  AuditsSites: "Sites",
};

export const MenuGroupMap = {
  Calendar: "Calendar",
  Cautions: "Cautions",
  CorrectiveActions: "Non Conformances",
  Customers: "Customers",
  Documentation: "Documentation",
  Locations: "Locations",
  Maintenance: "Maintenance",
  Procedures: "Checks",
  Production: "Production",
  Products: "Products",
  Reports: "Reports",
  StockControl: "Stock Control",
  Suppliers: "Suppliers",
  Incident: "Incidents",
  Accident: "Accidents",
  Miscellaneous: "Miscellaneous",
  Complaints: "Complaints",
  Training: "Training",
  GoodsIn: "Goods In",
  Audits: "Audits",
};
export const MenuGroupSequenceMap = {
  Calendar: 7,
  Cautions: 4,
  CorrectiveActions: 2,
  Customers: 14,
  Documentation: 8,
  Locations: 6,
  Maintenance: 18,
  Procedures: 1,
  Production: 15,
  Products: 10,
  Reports: 19,
  StockControl: 11,
  Suppliers: 12,
  Incident: 3,
  Miscellaneous: 17,
  Complaints: 5,
  Training: 9,
  GoodsIn: 13,
  Audits: 16,
  Accident: 20,
};
export const ComplaintDisplayNameMap = {
  ReportNumber: "Complaint No",
  CustomerComplaintNo: "Customer Complaint No",
  ReportedDate: "Reported Date/Time",
  ReportTakenBy: "Report Taken By",
  ComplainCost: "Complaint Cost (£)",
  CloseDownDateTime: "Close Down Time",
  BatchCodes: "Batch Code(s)",
  ProductDesc: "Product Description(s)",
  ProductCode: "Product Code(s)",
  ComplaintCodeId: "Complaint Category",
  Action: "Action Taken and Processing Details",
  ConclusionRemedialAction: "Conclusion and Remedial Actions",
  PreventativeAction: "Preventative Actions",
  RootCause: "Root Cause",
  Responsibility: "Responsibility(%)",
  Investigation: "Investigation",
  RiskAssessmentTimescale: "Resolve By (Date and Time)",
  CustomerNote: "Customer Name",
  CustomerCode: "Customer Code",
  CreatedBy: "User Name",
  ReceivedByMethod: "Receive By Method",
  DeliveryDate: "Delivery Date",
  ContactEmailAddress: "Contact Email Address",
  IsEmailSent: "Is Email Sent",
};

export const ExceptionDisplayNameMap: Record<any, string> = {
  TypeProcedure: "Type Procedure",
  ProcedureStepDescription: "Step Description",
  ExceptionType: "Exception Type",
  CorrectiveActionType: "NC Step",
  WorkRequestNo: "WRN",
  SCWorkRequestNo: "SNC",
  AssignTo: "Permitted User(s) to Manage NC",
  AssignedUser: "Assigned To",
  ResolveByDate: "Resolve By (Date & Time)",
  ExceptionMessage: "NC Message",
  ExceptionDate: "Exception Date",
  ExceptionCreateUser: "Created By",
  ExceptionPassUser: "Exception Pass By",
  ResolvedDate: "Resolved",
  ActionRequired: "Action Required",
  ActionTaken: "Preventative Actions Comments",
  ActionTakenListId: "Preventative Actions",
  AssessmentOfConsequences: "Assessment Of Consequences",
  CommentsTakenOnPC: "Web Comments",
  ExceptionImages: "NC Images",
  DateRange: "Date Range",
  FromDate: "From",
  ToDate: "To",
  LocationObjectId: "Location",
  RootCause: "Root Cause Additional Comments",
  RootCauseId: "Root Cause",
  StepNo: "Step No",
  IsSendEmail: "Send Email",
  EmailQueueStatus: "Email Status",
  ProductId: "Product Desc",
  ProductBatchCode: "Batch Code",
  ProductComment: "Product Comment",
  ShiftId: "Shift",
  OccurrenceTime: "Date / Time of Occurrence",
  SubNCId: "Sub NC",
  NCId: "NC",
  VerifiedById: "Verified By",
  VerifiedBy: "Verified By",
  CloseDownDateTime: "Date/Time Close Down",
  MarkSCExceptionFindingAsComplete: "OUR FINDINGS MARKED AS COMPLETED",
  IsSCExceptionFindingSkipped: "OUR FINDINGS SKIPPED",
  MarkSCExceptionActionAsComplete: "OUR ACTIONS MARKED AS COMPLETED",
  IsSCExceptionActionSkipped: "OUR ACTIONS SKIPPED",
  MarkSCExceptionSupplierInvestigationAsComplete:
    "SUPPLIER INVESTIGATION & RESPONSE MARKED AS COMPLETED",
  IsSCExceptionSupplierInvestigationSkipped:
    "SUPPLIER INVESTIGATION & RESPONSE SKIPPED",
  MarkSCExceptionSupplierCATakenAsComplete:
    "SUPPLIER CORRECTIVE ACTION(S) TAKEN MARKED AS COMPLETED",
  IsSCExceptionSupplierCATakenSkipped:
    "SUPPLIER CORRECTIVE ACTION(S) TAKEN SKIPPED",
  MarkInvestigationAsComplete: "Investigation",
  IsInvestigationSkipped: "Investigation Skipped",
  MarkConsequenceAssessmentAsComplete: "Assessment of Consequences",
  IsConsequenceAssessmentSkipped: "Consequence Assessment Skipped",
  MarkRequiredActionAsComplete: "Mark Required Action As Complete",
  MarkTakenActionAsComplete: "Immediate Action",
  IsActionTakenSkipped: "Action Skipped",
  IsRootCauseSkipped: "Root Cause Skipped",
  IsPreventativeActionSkipped: "Preventative Action Skipped",
  HasSupplierEmailSent: "SUPPLIER EMAIL SENT",
  IsResponseRequired: "Response Required?",
  HasSupplierResponded: "Supplier Responded?",
  ApprovedById: "Approved By",
  ApprovedBy: "Approved By",
  MarkRootCauseAsComplete: "Root Cause",
  MarkPreventativeActionAsComplete: "Preventative Action",
  IsNcCreatedInError: "Nc Created In Error",
  StepSequencePosition: "Step Sequence Position",
  LastModifiedByDate: "Updated date",
};

export const LabelSizeOptions = [
  {
    value: "Label60x30",
    label: "Label 60mm x 30mm",
  },
  {
    value: "Label100x60",
    label: "Label 100mm x 60mm",
  },
];

export const PrintingLabelSizeOptions = [
  {
    value: "Label60x30",
    label: "Label 60mm x 30mm",
  },
  {
    value: "Label80x60",
    label: "Label 80mm x 60mm",
  },
  {
    value: "Label76x51",
    label: "Label 76mm x 51mm",
  },
  {
    value: "Label101x54",
    label: "Label 101mm x 54mm",
  },
];

export const SubLocationStatus = [
  {
    value: "0",
    label: "Select Status",
  },
  {
    value: "InUse",
    label: "In Use",
  },
  {
    value: "NotInUse",
    label: "Not In Use",
  },
  {
    value: "OffSite",
    label: "Off Site",
  },
  {
    value: "Repair",
    label: "Repair",
  },
];

export const StockCheckOptions = [
  {
    value: "ALL",
    label: "ALL",
  },
  {
    value: "Customer",
    label: "CUSTOMER",
  },
  {
    value: "Supplier",
    label: "SUPPLIER",
  },
];

export const ProductByOptions = [
  {
    value: "",
    label: "Select Product By",
  },
  {
    value: "ProductCode",
    label: "Product Code",
  },
  {
    value: "ProductDescription",
    label: "Product Description",
  },
];

export const ReviewFrequency = [
  {
    value: null,
    label: "Select Frequency",
  },
  {
    value: "Daily",
    label: "Daily",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Yearly",
    label: "Yearly",
  },
  {
    value: "AsRequired",
    label: "As Required",
  },
];

export const UnitOfMeasurement = [
  {
    value: null,
    label: "Select Unit of Measurement",
  },
  {
    value: "g",
    label: "g",
  },
  {
    value: "kilo",
    label: "Kilo",
  },
];

export const ServiceType = [
  {
    value: null,
    label: "Select Service Type",
  },
  {
    value: "Breakdown",
    label: "Breakdown",
  },
  {
    value: "Service",
    label: "Service",
  },
  {
    value: "BreakdownAndService",
    label: "Breakdown and Service",
  },
];
